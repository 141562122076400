import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import EventsHeader from "../components/EventsHeader";
import Footer from "../components/Footer";
// import ResearchList from "../components/ResearchList";
import PolicyList from '../components/PolicyList';

const ResearchPage = () => {
    return (
        <Layout pageTitle="Events | LUTH">
            <NavOne />
            <EventsHeader title="" />
            <PolicyList />
            <Footer />
        </Layout>
    );
};

export default ResearchPage;